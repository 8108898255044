import { create } from "css-in-bun" with {
    type: "macro"
};
export const common = create({
    SubTitle: {
        gridArea: "title"
    },
    SubTitleSide: {
        gridArea: "side"
    },
    SubTitleClose: {
        cursor: "pointer",
        display: "grid",
        height: 24,
        fontSize: 14,
        gap: 4,
        gridTemplateColumns: "16px 1fr",
        alignItems: "center",
        backgroundColor: "var(--secondary)",
        color: "var(--secondary-inverse)",
        padding: "0 8px",
        borderRadius: 100
    },
    SubTitleContainer: {
        display: "grid",
        gap: "0.5em",
        fontWeight: 400,
        alignItems: "center",
        position: "sticky",
        top: "var(--sticky-offset, 0)",
        backgroundColor: "var(--background-color)",
        zIndex: 1,
        margin: "0 -0.5em",
        padding: "0.5em",
        userSelect: "none",
        WebkitUserSelect: "none",
        gridTemplate: '"bar title side" auto / auto 1fr auto',
        height: 40,
        "::before": {
            gridArea: "bar",
            content: '""',
            height: "1em",
            width: "0.2em",
            borderRadius: "0.2em",
            backgroundColor: "var(--primary)"
        }
    }
});
